<template>
    <div class="home-view">
      <h1>Welcome to My Portfolio</h1>
      <p>Hi, I'm [Your Name]. I'm a [Your Profession] passionate about creating amazing web experiences.</p>
    </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    isDarkMode: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-dark-mode']
}
</script>

<style scoped>
.home-view {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dark-mode-toggle {
  margin-top: 20px;
}

input[type="checkbox"] {
  width: 0;
  height: 0;
  visibility: hidden;
}

label {
  display: block;
  width: 70px;  /* Increased from 60px to 70px */
  height: 34px;  /* Increased from 30px to 34px */
  background-color: #83d8ff;
  border-radius: 17px;  /* Half of the height */
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.toggle-icon {
  width: 28px;  /* Increased from 24px to 28px */
  height: 28px;  /* Increased from 24px to 28px */
  position: absolute;
  top: 3px;
  left: 3px;
  transition: 0.5s;
}

/* Sun shape */
.toggle-icon::before {
  content: "";
  position: absolute;
  left: 2px;
  width: 100%;
  height: 100%;
  background-color: #ffdb3b;
  border-radius: 50%;
  transition: 0.3s;
}

input:checked + label {
  background-color: #3a3a3a;
}

input:checked + label .toggle-icon {
  left: calc(100% - 35px);  /* Adjusted for the new width */
}

/* Moon shape */
input:checked + label .toggle-icon::before {
  background-color: transparent;
  box-shadow: inset -4px -2px 0 0 #fff;  /* Slightly larger moon */
  transform: rotate(-65deg);
}
</style>