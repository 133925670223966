<template>
  <nav class="side-navigation">
    <ul>
      <li v-for="(section, index) in sections" :key="section">
        <a 
          @click.prevent="handleClick(index)" 
          :href="`#${section}`" 
          :class="{ active: currentSectionIndex === index }" 
          :style="{ '--underline-color': getDotColor(section) }"
        >
          <span class="dot" :style="{ backgroundColor: getDotColor(section) }"></span>
          <span class="section-name">{{ section }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'SideNavigation',
  props: {
    currentSectionIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const sections = ['home', 'about', 'projects', 'skills', 'experience', 'contact']

    const getDotColor = (section) => {
      const colors = {
        home: '#ff6b6b',
        about: '#4ecdc4',
        projects: '#45aaf2',
        skills: '#feca57',
        experience: '#ff9ff3',
        contact: '#FFFFFF'
      }
      return colors[section] || '#000000'
    }

    const handleClick = (index) => {
      emit('nav-click', index)
    }

    return {
      sections,
      getDotColor,
      handleClick
    }
  }
}
</script>

<style scoped>
.side-navigation {
  position: sticky;
  top: 30vh;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--nav-bg-color);
  opacity: var(--nav-opacity);
  padding: 20px;
  margin-right: 35px;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
  width: 100px;
  height: 250px;
  border-radius: 15px;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .side-navigation {
    opacity: 1;
    background-color: var(--nav-bg-color-transparent);
    -webkit-backdrop-filter: var(--frosted-backdrop);
    backdrop-filter: var(--frosted-backdrop);
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

li {
  margin: 15px 0;
}

a {
  display: flex;
  align-items: center;
  color: var(--nav-text-color);
  text-decoration: none;
  font-size: 14px;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.3s ease;
  position: relative;
}

a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--underline-color);
  transition: width 0.3s ease;
}

a:hover::after,
a.active::after {
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .side-navigation {
    width: 60px;
    padding: 10px 5px;
    margin-right: 5px;
    height: 150px; /* Reduced height for mobile */
  }

  a {
    font-size: 10px;
    padding: 3px;
    justify-content: center;
  }

  .dot {
    display: none;
  }

  .section-name {
    margin-left: 0;
  }

  li {
    margin: 8px 0; /* Reduced margin between items */
  }
}
</style>