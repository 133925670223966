<template>
  <div class="main-content">
    <div class="content-wrapper" ref="contentWrapper">
      <section v-for="(section, index) in sections" :key="section" :id="section" :data-index="index">
        <component 
          :is="`${section.charAt(0).toUpperCase() + section.slice(1)}View`" 
          :isDarkMode="isDarkMode"
          @toggle-dark-mode="$emit('toggle-dark-mode')"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import SkillsView from '../views/SkillsView.vue'
import ExperienceView from '../views/ExperienceView.vue'
import ContactView from '../views/ContactView.vue'

export default {
  name: 'MainContent',
  components: {
    HomeView, AboutView, ProjectsView, SkillsView, ExperienceView, ContactView
  },
  props: {
    isDarkMode: {
      type: Boolean,
      required: true
    }
  },
  emits: ['section-change', 'toggle-dark-mode'],
  setup(props, { emit }) {
    const sections = ['home', 'about', 'projects', 'skills', 'experience', 'contact']
    const currentSectionIndex = ref(0)
    const contentWrapper = ref(null)
    let isScrolling = false
    let lastScrollTime = 0
    let scrollAccumulator = 0
    const scrollThreshold = 500 // Adjust this value to change sensitivity
    let touchStartY = 0

    const scrollToSection = (index) => {
      if (index < 0 || index >= sections.length) return
      contentWrapper.value.classList.add('scrolling')
      contentWrapper.value.style.transform = `translateY(-${index * 100}vh)`
      currentSectionIndex.value = index
      emit('section-change', index)
      
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          contentWrapper.value.classList.remove('scrolling')
        })
      })
    }

    const handleScroll = (deltaY) => {
      const now = performance.now()

      if (isScrolling || now - lastScrollTime < 500) return

      scrollAccumulator += deltaY

      if (Math.abs(scrollAccumulator) >= scrollThreshold) {
        const direction = scrollAccumulator > 0 ? 1 : -1
        const newIndex = currentSectionIndex.value + direction

        if (newIndex >= 0 && newIndex < sections.length) {
          isScrolling = true
          lastScrollTime = now
          scrollToSection(newIndex)
          
          setTimeout(() => {
            isScrolling = false
          }, 800)
        }

        scrollAccumulator = 0
      }
    }

    const handleWheel = (event) => {
      event.preventDefault()
      handleScroll(event.deltaY)
    }

    const handleTouchStart = (event) => {
      touchStartY = event.touches[0].clientY
    }

    const handleTouchMove = (event) => {
      event.preventDefault()
      const touchEndY = event.touches[0].clientY
      const deltaY = touchStartY - touchEndY
      handleScroll(deltaY)
      touchStartY = touchEndY
    }

    onMounted(() => {
      window.addEventListener('wheel', handleWheel, { passive: false })
      contentWrapper.value.addEventListener('touchstart', handleTouchStart, { passive: false })
      contentWrapper.value.addEventListener('touchmove', handleTouchMove, { passive: false })
    })

    onUnmounted(() => {
      window.removeEventListener('wheel', handleWheel)
      contentWrapper.value.removeEventListener('touchstart', handleTouchStart)
      contentWrapper.value.removeEventListener('touchmove', handleTouchMove)
    })

    return {
      currentSectionIndex,
      sections,
      scrollToSection,
      contentWrapper
    }
  }
}
</script>

<style scoped>
.main-content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.content-wrapper {
  height: 100%;
  transition: transform 0.3s ease; /* Reduced transition time */
}

.content-wrapper.scrolling {
  filter: blur(5px);
  transition: filter 0.3s ease, transform 0.3s ease;
}

section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease; /* Reduced transition time */
}

.content-wrapper.scrolling section {
  opacity: 0.7; /* Increased opacity for better visibility */
}

/* Add this new rule for vertical-only blur */
@supports (backdrop-filter: blur(10px)) {
  .content-wrapper.scrolling {
    filter: none;
    backdrop-filter: blur(10px) saturate(150%);
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-right: 30px;
  }
}
</style>
