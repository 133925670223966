<template>
  <canvas ref="canvas" :width="width" :height="height" :style="canvasStyle"></canvas>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'

export default {
  name: 'BackgroundFlares',
  props: {
    isDarkMode: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const canvas = ref(null)
    const width = ref(window.innerWidth)
    const height = ref(window.innerHeight)
    const flares = ref([])
    let animationId = null

    const colors = ['#ff6b6b', '#4ecdc4', '#45aaf2', '#feca57', '#ff9ff3', '#54a0ff']

    const getRandomColor = () => {
      return colors[Math.floor(Math.random() * colors.length)]
    }

    const createFlares = () => {
      flares.value = Array.from({ length: 8 }, () => ({
        x: Math.random() * width.value,
        y: Math.random() * height.value,
        size: Math.random() * (props.isDarkMode ? 200 : 300) + (props.isDarkMode ? 100 : 150),
        color: getRandomColor(),
        vx: (Math.random() - 0.5), // Increased velocity
        vy: (Math.random() - 0.5), // Increased velocity
        opacity: props.isDarkMode ? 0.03 : 0.4
      }))
    }

    const updateFlares = () => {
      flares.value.forEach(flare => {
        flare.x += flare.vx
        flare.y += flare.vy

        if (flare.x < -flare.size / 2 || flare.x > width.value + flare.size / 2) flare.vx *= -1
        if (flare.y < -flare.size / 2 || flare.y > height.value + flare.size / 2) flare.vy *= -1
      })
    }

    const drawFlares = () => {
      if (!canvas.value) return

      const ctx = canvas.value.getContext('2d')
      ctx.clearRect(0, 0, width.value, height.value)

      flares.value.forEach(flare => {
        const gradient = ctx.createRadialGradient(flare.x, flare.y, 0, flare.x, flare.y, flare.size / 2)
        
        if (props.isDarkMode) {
          gradient.addColorStop(0, flare.color + '10')  // 6.25% opacity at center
          gradient.addColorStop(0.5, flare.color + '05')  // 2% opacity at middle
          gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')
        } else {
          gradient.addColorStop(0, flare.color)
          gradient.addColorStop(0.6, flare.color + '40')
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')
        }

        ctx.beginPath()
        ctx.arc(flare.x, flare.y, flare.size / 2, 0, Math.PI * 2)
        ctx.fillStyle = gradient
        ctx.globalAlpha = flare.opacity
        ctx.fill()
      })
    }

    let lastTime = 0
    const fps = 30 // Limit to 30 frames per second
    const fpsInterval = 1000 / fps

    const animateWithFpsLimit = (currentTime) => {
      animationId = requestAnimationFrame(animateWithFpsLimit)

      const elapsed = currentTime - lastTime

      if (elapsed > fpsInterval) {
        lastTime = currentTime - (elapsed % fpsInterval)
        updateFlares()
        drawFlares()
      }
    }

    const handleResize = () => {
      width.value = window.innerWidth
      height.value = window.innerHeight
      if (canvas.value) {
        canvas.value.width = width.value
        canvas.value.height = height.value
      }
      createFlares()
    }

    watch(() => props.isDarkMode, () => {
      createFlares()
    })

    onMounted(() => {
      createFlares()
      animationId = requestAnimationFrame(animateWithFpsLimit)
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      cancelAnimationFrame(animationId)
      window.removeEventListener('resize', handleResize)
    })

    const canvasStyle = computed(() => ({
      backgroundColor: `var(--bg-color)`
    }))

    return { canvas, width, height, canvasStyle }
  }
}
</script>

<style scoped>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
</style>