<template>
    <div class="skills-view">
      <h2>My Skills</h2>
      <ul>
        <li>Skill 1</li>
        <li>Skill 2</li>
        <li>Skill 3</li>
        <li>Skill 4</li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'SkillsView'
}
</script>

<style scoped>
.skills-view {
  padding: 20px;
}
</style>