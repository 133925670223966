<template>
  <div class="experience-view">
    <h2>Work Experience</h2>
    <div class="job">
      <h3>[Job Title] at [Company]</h3>
      <p>[Start Date] - [End Date]</p>
      <p>[Brief Job Description]</p>
    </div>
    <!-- Add more job entries as needed -->
  </div>
</template>

<script>
export default {
  name: 'ExperienceView'
}
</script>

<style scoped>
.experience-view {
  padding: 20px;
}
</style>