<template>
    <div class="logo-container">
      <svg :style="{ color: svgColor }" width="80" height="80" viewBox="0 0 100 100">
        <rect x="0" y="0" width="100" height="100" fill="none" stroke="currentColor" stroke-width="4"/>
        <text x="50" y="45" text-anchor="middle" class="main-text">sv_</text>
        <text x="50" y="70" text-anchor="middle" class="sub-text">Sandor Vunk</text>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AnimatedLogo',
    props: {
      isDarkMode: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      svgColor() {
        return this.isDarkMode ? '#ffffff' : '#000000';
      }
    }
  }
  </script>
  
  <style scoped>
  .logo-container {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1000;
  }
  
  svg {
    width: 80px;
    height: 80px;
  }
  
  .main-text {
    font-size: 20px;
    font-weight: bold;
    fill: currentColor;
  }
  
  .sub-text {
    font-size: 12px;
    font-weight: bold;
    fill: currentColor;
  }
  
  @media (max-width: 768px) {
    .logo-container {
      top: 10px;
      left: 10px;
    }

    svg {
      width: 60px;
      height: 60px;
    }

    .main-text {
      font-size: 16px;
    }

    .sub-text {
      font-size: 10px;
    }
  }
  </style>
