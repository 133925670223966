<template>
  <div class="contact-view">
    <h2>Contact Me</h2>
    <p>Email: your.email@example.com</p>
    <p>LinkedIn: [Your LinkedIn Profile]</p>
    <p>GitHub: [Your GitHub Profile]</p>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
}
</script>