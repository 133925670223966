<template>
    <div class="projects-view">
      <h2>My Projects</h2>
      <ul>
        <li>Project 1: [Brief Description]</li>
        <li>Project 2: [Brief Description]</li>
        <li>Project 3: [Brief Description]</li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'ProjectsView'
}
</script>

<style scoped>
.projects-view {
  padding: 20px;
}
</style>