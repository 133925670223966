<template>
    <div class="about-view">
      <h2>About Me</h2>
      <p>I'm a [Your Profession] with [X] years of experience. I specialize in [Your Specialties] and love working on challenging projects.</p>
    </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>

<style scoped>
.about-view {
  padding: 20px;
}
</style>